<template>
  <div
    :class="['container-xl px-0 px-sm-3',
      { 'ocultar-overflow-vista': ocultarOverflowEnVistaPaquetes }]"
  >
    <!-- header -->
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Paquetes</h2>
      <div
        v-if="!$apolloData.queries.cliente.loading"
        class="col-auto"
      >
        <mdb-btn
          flat
          dark-waves
          icon="redo-alt"
          :disabled="botonDeshabilitado"
          class="btn-bordeado my-sm-0 px-3 bg-white"
          @click="paqueteActualizar('filtro')"
        >
          Actualizar
        </mdb-btn>
        <mdb-btn
          color="primario"
          icon="file-invoice"
          :disabled="botonDeshabilitado"
          class="btn-bordeado my-sm-0 px-3 bg-white"
          @click="listarOrdenesReempaque=true;mostrarOrdenesReempaque=!mostrarOrdenesReempaque"
        >
          Órdenes de reempaque
        </mdb-btn>
      </div>
    </div>
    <CargandoVista
      v-if="$apolloData.queries.cliente.loading"
      clase-adicional="vc-75vh"
    />
    <div v-else class="mb-page">
      <!-- Filtros -->
      <div
        class="row align-items-center justify-content-center
          justify-content-md-start my-1 mx-0"
      >
        <div class="col-auto pl-md-0">
          <mdb-btn
            flat
            dark-waves
            icon="list"
            :disabled="botonDeshabilitado"
            class="btn-bordeado my-sm-0 py-2 px-3"
            @click="paqueteActualizar();
              buscarPor.estatus = '';"
          >
            Todos
          </mdb-btn>
        </div>
        <div class="col-auto col-md-auto px-0">
          <div class="md-form md-outline outline-select my-2">
            <select
              id="estatus-select"
              v-model="buscarPor.estatus"
              class="custom-select"
              @change="paqueteActualizar('estatus')"
            >
              <option class="d-none" disabled value="">Seleccione</option>
              <option
                v-for="(value, key) in PaqueteEstatus"
                :key="key"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
            <label
              for="estatus-select"
              :class="buscarPor.estatus ? 'label-active' : 'label-inactive'"
            >
              Estatus
            </label>
          </div>
        </div>
        <div
          :class="['col-auto botones-opcionales',
            { mostrar: paquetesSeleccionados.length }, 'pl-0 pr-md-0']"
        >
          <mdb-btn
            color="terciario"
            icon="box-open"
            :disabled="botonDeshabilitado || !paquetesTipoIndividual"
            class="px-3"
            @click="verificarPaquetesAReempacar"
          >
            Nueva orden de reempaque
          </mdb-btn>
          <mdb-btn
            color="terciario"
            icon="clipboard-list"
            :disabled="botonDeshabilitado || !paquetesSeleccionados.length"
            class="px-3"
            @click="verificarPaquetesAInstruccionEnvio"
          >
            Asignar instrucción de envío
          </mdb-btn>
        </div>
      </div>
      <!-- Tabla -->
      <ul
        data-table="sticky"
        class="table mb-3"
      >
        <li class="encabezado row">
          <div class="col-5 col-sm-3 col-md-2">Fecha recibido</div>
          <div class="col-4 col-md-3 d-none d-md-block">Estatus</div>
          <div class="col">Detalles</div>
          <div class="col-4 col-md d-none d-sm-block">Receptor</div>
        </li>
        <li
          v-for="paquete in cliente.paquetes"
          :key="`paquete-${paquete.id}`"
          class="contenido row align-items-center"
          @click="$router.push({ name: 'Paquete', params: { paqueteId: paquete.id } })"
        >
        <!-- Fecha recibido -->
          <div class="col-5 col-sm-3 col-md-2 fecha">
            <label
              class="opciones-checkbox py-3"
              title="Seleccionar paquete"
              @click.stop
            >
              <input
                v-model="paquetesSeleccionados"
                type="checkbox"
                name="selecionar-paquete-checkbox"
                :value="paquete"
              />
              <span class="icono-checkbox" />
              <span class="d-block">
                {{ formatoFecha(paquete.fecha.formatted).ddmm }}
              </span>
              {{ formatoFecha(paquete.fecha.formatted).yyyy }}
            </label>
          </div>
          <!-- Estatus -->
          <div class="col-4 col-md-3 d-none d-md-block">
            <img
              v-if="paquete.estatus && paquete.estatus.length"
              :src="`/img/iconos-vistas/estatus-paquete/${paquete.estatus}.svg`"
              alt="Icono"
              height="18"
              width="18"
            >
            {{PaqueteEstatus[paquete.estatus]}}
            <p class="mb-0">
              Envío:
              <font-awesome-icon
                v-if="paquete.envio"
                :icon="ConsolidadoTipoEnvioIconos[paquete.envio]"
                class="texto-terciario ml-1"
              />
              <span :class="{'text-muted font-italic': !paquete.envio}">
                {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : 'Sin especificar' }}
              </span>
            </p>
            <p class="mb-0">
              Tipo:
              <img
                v-if="paquete.tipo && paquete.tipo.length"
                :src="`/img/iconos-vistas/paquete-tipo/${paquete.tipo}.svg`"
                alt="Icono"
                height="18"
                width="18"
              >
              <span :class="{'text-muted font-italic': !paquete.tipo}">
                {{ paquete.tipo ? paquete.tipo : 'Sin especificar' }}
              </span>
            </p>
          </div>
          <!-- Detalles -->
          <div class="col">
            <!-- Columna estatus -->
            <div class="d-md-none mb-2">
              <img
                v-if="paquete.estatus && paquete.estatus.length"
                :src="`/img/iconos-vistas/estatus-paquete/${paquete.estatus}.svg`"
                alt="Icono"
                height="18"
                width="18"
              >
              {{PaqueteEstatus[paquete.estatus]}}
              <p class="mb-0">
                Envío:
                <font-awesome-icon
                  v-if="paquete.envio"
                  :icon="ConsolidadoTipoEnvioIconos[paquete.envio]"
                  class="texto-terciario ml-1"
                />
                <span :class="{'text-muted font-italic': !paquete.envio}">
                  {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : 'Sin especificar' }}
                </span>
              </p>
              <p class="mb-0">
                Tipo:
                <img
                  v-if="paquete.tipo && paquete.tipo.length"
                  :src="`/img/iconos-vistas/paquete-tipo/${paquete.tipo}.svg`"
                  alt="Icono"
                  height="18"
                  width="18"
                >
                <span :class="{'text-muted font-italic': !paquete.tipo}">
                  {{ paquete.tipo ? paquete.tipo : 'Sin especificar' }}
                </span>
              </p>
            </div>
            Tracking:
            <span :class="{'text-muted font-italic': !paquete.tracking}">
              {{ paquete.tracking ? paquete.tracking : 'Sin especificar' }}
            </span>
            <p class="mb-0">
              Pies cúbicos:
              <span title="Pies cúbicos">
                {{
                  mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728)
                }} ft<sup>3</sup>
              </span>
            </p>
            Peso:
            <span title="Libras">
              {{ mostrarSoloDosDecimales(paquete.peso) }} lb
            </span>
            <p class="mb-0">
              Peso por volúmen:
              <span title="Libras">
                {{
                  mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166)
                }} lb
              </span>
            </p>
            <!-- Columna receptor -->
            <p class="mb-0 d-sm-none">
              Receptor:
              <span :class="{'text-muted font-italic': !paquete.receptor}">
                {{ paquete.receptor ? paquete.receptor : 'Sin especificar' }}
              </span>
            </p>
            <p
              class="enlace-boton d-sm-none"
              @click.stop="paqueteSeleccionado = paquete;
                mostrarModalSeleccionarDireccion = true;"
            >
              <font-awesome-icon
                icon="edit"
                size="sm"
              />
              Instrucciones de envío
            </p>
            <p class="mb-0 d-sm-none">
              <!-- Dirección de envío: -->
              <span v-if="paquete.direccionDestino
                && paquete.direccionDestino.ciudad
                && paquete.direccionDestino.direccion"
              >
                {{ paquete.direccionDestino.ciudad.estado.nombre }},
                {{ paquete.direccionDestino.ciudad.nombre }},
                {{ paquete.direccionDestino.direccion }}
              </span>
              <span v-else class="text-muted font-italic">Sin especificar</span>
            </p>
            <p
              v-if="paquete.observacion"
              class="mb-0 texto-line-clamp"
            >
              Observación: {{ paquete.observacion }}
            </p>
          </div>
          <!-- Receptor -->
          <div class="col-4 col-md d-none d-sm-block">
            <span :class="{'text-muted font-italic': !paquete.receptor}">
              {{ paquete.receptor ? paquete.receptor : 'Sin especificar' }}
            </span>
            <p
              class="enlace-boton"
              @click.stop="paqueteSeleccionado = paquete;
                mostrarModalSeleccionarDireccion = true;"
            >
              <font-awesome-icon
                icon="edit"
                size="sm"
              />
              Instrucciones de envío
            </p>
            <p class="mb-0">
              <!-- Dirección de envío: -->
              <span v-if="paquete.direccionDestino
                && paquete.direccionDestino.ciudad
                && paquete.direccionDestino.direccion"
              >
                {{ paquete.direccionDestino.ciudad.estado.nombre }},
                {{ paquete.direccionDestino.ciudad.nombre }},
                {{ paquete.direccionDestino.direccion }}
              </span>
              <span v-else class="text-muted font-italic">Sin especificar</span>
            </p>
          </div>
        </li>
        <li
          v-if="!(cliente.paquetes && cliente.paquetes.length)"
          class="no-items"
        >
          No hay paquetes
        </li>
      </ul>
    </div>
    <!-- Modal Ordenes de reempaque -->
    <ModalOrdenReempaque
      :mostrar-modal="mostrarOrdenesReempaque"
      :paquetes="paquetesSeleccionados"
      :clienteId="clienteId"
      :listarOrdenes="listarOrdenesReempaque"
      @limpiarSelectPaquetes="paquetesSeleccionados = []"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarOrdenesReempaque = $event;
        listarOrdenesReempaque=false"
    />
    <!-- Modal Instrucciones de envío -->
    <ModalInstruccionesEnvio
      :mostrar-modal="mostrarInstruccionesEnvio"
      :paquetes="paquetesSeleccionados"
      :clienteId="clienteId"
      :variablesUpdateQuery="{
        filter: {
          id: leerPersonaId()
        },
        filters: {
          reempacado: false
        }
      }"
      @limpiarSelectPaquetes="paquetesSeleccionados = []"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarInstruccionesEnvio = $event;
        listarInstruccionesEnvio=false"
    />
    <!-- Modal Seleccionar Dirección -->
    <ModalSeleccionarDireccion
      :mostrar-modal="mostrarModalSeleccionarDireccion"
      :paqueteSeleccionado="paqueteSeleccionado"
      :clienteId="clienteId"
      @paqueteConDireccion="paqueteActualizarDireccion($event)"
      @alertaMensaje="alertaMensaje=$event"
      @close="mostrarModalSeleccionarDireccion = $event; paqueteSeleccionado = {}"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view :key="$route.fullPath"
        @actualizar="actualizarPaqueteDesdeRutaHija($event)"/>
    </transition>
  </div>
</template>

<script>
import ModalOrdenReempaque from '@/components/FormularioOrdenReempaque.vue'
import ModalInstruccionesEnvio from '@/components/FormularioInstruccionesEnvio.vue'
import ModalSeleccionarDireccion from '@/components/ModalSeleccionarDireccion.vue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import CargandoVista from '@/components/CargandoVista.vue'
import { PaqueteEstatus } from '@/constantes/paquetes.js'
import { ConsolidadoTipoEnvio, ConsolidadoTipoEnvioIconos } from '@/constantes/consolidados.js'
import { formatearFecha, mostrarSoloDosDecimales } from '@/funciones/funciones.js'
import gql from 'graphql-tag'
import clientePaquetesGql from '@/graphql/clientePaquetes.gql'
import { leerPersonaId } from '@/utils/datosToken.js'
import {
  mdbBtn
} from 'mdbvue'

export default {
  name: 'Paquetes',
  components: {
    mdbBtn,
    CargandoVista,
    AlertaMensaje,
    ModalSeleccionarDireccion,
    ModalOrdenReempaque,
    ModalInstruccionesEnvio
  },
  data () {
    return {
      leerPersonaId,
      // Instrucciones de envio
      mostrarInstruccionesEnvio: false,
      listarInstruccionesEnvio: false,
      // Ordenes de reempaque
      mostrarOrdenesReempaque: false,
      listarOrdenesReempaque: false,
      // Seleccionar dirección
      mostrarModalSeleccionarDireccion: false,
      // Otros
      paquetesSeleccionados: [],
      mostrarSoloDosDecimales,
      alertaMensaje: { contenido: '' },
      buscarPor: {
        estatus: ''
      },
      botonDeshabilitado: false,
      ConsolidadoTipoEnvio,
      ConsolidadoTipoEnvioIconos,
      PaqueteEstatus,
      cliente: {},
      paqueteSeleccionado: {},
      paquetes: [],
      // Datos del cliente
      clienteId: ''
    }
  },
  mounted () {
    this.clienteId = leerPersonaId()
  },
  computed: {
    ocultarOverflowEnVistaPaquetes () {
      const ruta = this.$route.path.slice(1).split('/')
      return !!(ruta[1] && ruta[1].length) // Si existe ruta hija return true
    },
    paquetesEsperaMiami () {
      if (!this.paquetesSeleccionados.length) return false
      let paqueteEnMiami = true
      this.paquetesSeleccionados.forEach((paquete) => {
        if (paquete.estatus !== 'EsperaMiami') {
          paqueteEnMiami = false
        }
      })
      if (paqueteEnMiami) {
      // retornará verdadero si todos los paquetes seleccionados tienen estatus "EsperaMiami"
        return true
      }
      return false
    },
    paquetesId () {
      if (!this.paquetesSeleccionados) return false
      const ids = []
      this.paquetesSeleccionados.forEach((paquete) => {
        ids.push(paquete.id)
      })
      // retornará todos los id de los paquetes seleccionados
      return ids
    },
    paquetesTipoIndividual () {
      if (!this.paquetesSeleccionados.length) return false
      const tipos = []
      this.paquetesSeleccionados.forEach((paquete) => {
        tipos.push(paquete.tipo)
      })
      if (Object.values(tipos).includes('Reempaque')) return false
      // retornará verdadero si todos los paquetes seleccionados son de tipo individual
      return true
    }
  },
  methods: {
    verificarPaquetesAReempacar () {
      if (this.paquetesSeleccionados.length === 1) {
        return (this.alertaMensaje = {
          contenido: 'Selecciona más de un paquete para continuar',
          tipo: 'advertencia'
        })
      }
      if (!this.paquetesEsperaMiami) {
        this.alertaMensaje = {
          contenido: 'Esta acción solo es permitida en los paquetes con estatus en almacén de Miami',
          tipo: 'advertencia'
        }
      }
      this.mostrarOrdenesReempaque = !this.mostrarOrdenesReempaque
    },
    verificarPaquetesAInstruccionEnvio () {
      if (!this.paquetesEsperaMiami) {
        this.alertaMensaje = {
          contenido: 'Esta acción solo es permitida en los paquetes con estatus en almacén de Miami',
          tipo: 'advertencia'
        }
      }
      this.mostrarInstruccionesEnvio = !this.mostrarInstruccionesEnvio
    },
    // Dar formato a fecha
    formatoFecha (fecha) {
      return formatearFecha(fecha)
    },
    // Funcionalidad de paquetes
    paqueteActualizarDireccion (paqueteConDireccion) {
      this.cliente.paquetes.map((p) => {
        const paquete = p
        if (p.id === paqueteConDireccion.id) {
          paquete.direccionDestino = paqueteConDireccion.direccionDestino
        }
        return (paquete)
      })
      this.paqueteSeleccionado = {}
      this.mostrarModalSeleccionarDireccion = false
    },
    actualizarPaqueteDesdeRutaHija (paquete) {
      if (paquete?.id) {
        this.cliente.paquetes.map((p) => {
          const paqueteConFormato = p
          if (p.id === paquete.id) {
            Object.assign(paqueteConFormato, paquete)
          }
          return paqueteConFormato
        })
      }
    },
    paqueteActualizar (filtro) {
      this.botonDeshabilitado = true
      let variableParaQuery = {}
      if (filtro && this.buscarPor.estatus) {
        variableParaQuery = {
          filter: {
            id: this.clienteId
          },
          filters: {
            estatus: this.buscarPor.estatus,
            reempacado: false
          }
        }
      } else {
        variableParaQuery = {
          filter: {
            id: this.clienteId
          },
          filters: {
            reempacado: false
          }
        }
      }
      this.$apollo
        .watchQuery({
          query: gql`
            ${clientePaquetesGql}
          `,
          variables: variableParaQuery,
          update: (data) => data.Cliente[0],
          fetchPolicy: 'cache-and-network'
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.Cliente && data.Cliente[0]) {
              this.cliente.paquetes = data.Cliente[0].paquetes
            }
            this.botonDeshabilitado = false
          },
          error: () => {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un error actualizando los paquetes. Por favor intenta de nuevo',
              tipo: 'error'
            }
            this.botonDeshabilitado = false
          }
        })
    }
  },
  apollo: {
    cliente () {
      return {
        query: clientePaquetesGql,
        variables: {
          filter: {
            id: leerPersonaId()
          },
          filters: {
            reempacado: false
          }
        },
        update: (data) => data.Cliente[0],
        fetchPolicy: 'cache-and-network'
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.mb-page {
  margin-bottom: 11rem !important;
  @media screen and (min-width: 768px) {
    margin-bottom: 4rem !important;
  }
}
.contenido > .fecha {font-size: 1.1rem;}
.enlace-boton {
  cursor: pointer;
  font-weight: 500;
  margin: .5rem 0 0;
  user-select: none;
}
.botones-opcionales {
  bottom: 73px;
  height: 0;
  overflow: hidden;
  position: fixed;
  right: 0px;
  text-align: right;
  transition: height 0.5s ease;
  width: 276px;
  z-index: 2;

  @media screen and (min-width: 298px) {width: 298px;}
  @media screen and (min-width: 768px) {
    bottom: 1rem;
    right: calc(50% - 300px);
    text-align: center;
    width: 600px;
  }
  @media screen and (min-width: 992px) {
    right: calc(50% - 113px - 300px);
  }

  &.mostrar {
    height: 158px;
    @media screen and (min-width: 298px) {height: 118px;}
    @media screen and (min-width: 576px) {height: 110px;}
    @media screen and (min-width: 768px) {height: 60px;}
  }
}
</style>
