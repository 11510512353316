<template>
  <mdb-modal
    centered
    elegant
    size="lg"
    scrollable
    :show="mostrarModal"
    @close="cerrarModal()"
  >
    <mdb-modal-header>
      <mdb-modal-title>Instrucciones de envío</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body class="pt-0">
      <!-- Caso crear intrucción de envío -->
      <div>
        <header class="font-weight-bold">Paquetes seleccionados</header>
        <ul class="table" data-table="sticky">
          <li class="encabezado row">
            <div class="col-3 col-sm-2 col-lg-1">Fecha</div>
            <div class="col-4 col-md-3 d-none d-lg-block">Estatus</div>
            <div class="col">Detalles</div>
            <div class="col-4 col-md d-none d-sm-block">Receptor</div>
          </li>
          <li
            v-for="paquete in paquetes"
            :key="`paquete-${paquete.id}`"
            class="contenido normal row align-items-center"
          >
          <!-- Fecha recibido -->
            <div class="col-3 col-sm-2 col-lg-1">
              <span class="d-block">
                {{ formatearFecha(paquete.fecha.formatted).ddmm }}
              </span>
              {{ formatearFecha(paquete.fecha.formatted).yyyy }}
            </div>
            <!-- Estatus -->
            <div class="col-4 col-md-3 d-none d-lg-block">
              <img
                v-if="paquete.estatus && paquete.estatus.length"
                :src="`/img/iconos-vistas/estatus-paquete/${paquete.estatus}.svg`"
                alt="Icono"
                height="18"
                width="18"
              >
              {{PaqueteEstatus[paquete.estatus]}}
              <p class="mb-0">
                Envío:
                <font-awesome-icon
                  v-if="paquete.envio"
                  :icon="ConsolidadoTipoEnvioIconos[paquete.envio]"
                  class="texto-terciario ml-1"
                />
                <span :class="{'text-muted font-italic': !paquete.envio}">
                  {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : 'Sin especificar' }}
                </span>
              </p>
              <p class="mb-0">
                Tipo:
                <img
                  v-if="paquete.tipo && paquete.tipo.length"
                  :src="`/img/iconos-vistas/paquete-tipo/${paquete.tipo}.svg`"
                  alt="Icono"
                  height="18"
                  width="18"
                >
                <span :class="{'text-muted font-italic': !paquete.tipo}">
                  {{ paquete.tipo ? paquete.tipo : 'Sin especificar' }}
                </span>
              </p>
            </div>
            <!-- Detalles -->
            <div class="col">
              <!-- Columna estatus -->
              <div class="d-lg-none mb-1">
                <img
                  v-if="paquete.estatus && paquete.estatus.length"
                  :src="`/img/iconos-vistas/estatus-paquete/${paquete.estatus}.svg`"
                  alt="Icono"
                  height="18"
                  width="18"
                >
                {{PaqueteEstatus[paquete.estatus]}}
                <p class="mb-0">
                  Envío:
                  <font-awesome-icon
                    v-if="paquete.envio"
                    :icon="ConsolidadoTipoEnvioIconos[paquete.envio]"
                    class="texto-terciario ml-1"
                  />
                  <span :class="{'text-muted font-italic': !paquete.envio}">
                    {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : 'Sin especificar' }}
                  </span>
                </p>
                <p class="mb-0">
                  Tipo:
                  <img
                    v-if="paquete.tipo && paquete.tipo.length"
                    :src="`/img/iconos-vistas/paquete-tipo/${paquete.tipo}.svg`"
                    alt="Icono"
                    height="18"
                    width="18"
                  >
                  <span :class="{'text-muted font-italic': !paquete.tipo}">
                    {{ paquete.tipo ? paquete.tipo : 'Sin especificar' }}
                  </span>
                </p>
              </div>
              Tracking:
              <span :class="{'text-muted font-italic': !paquete.tracking}">
                {{ paquete.tracking ? paquete.tracking : 'Sin especificar' }}
              </span>
              <p class="mb-0">
                Pies cúbicos:
                <span title="Pies cúbicos">
                  {{
                    mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728)
                  }} ft<sup>3</sup>
                </span>
              </p>
              Peso:
              <span title="Libras">
                {{ mostrarSoloDosDecimales(paquete.peso) }} lb
              </span>
              <p class="mb-0">
                Peso por volúmen:
                <span title="Libras">
                  {{
                    mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166)
                  }} lb
                </span>
              </p>
              <!-- Columna receptor -->
              <p class="mb-0 mt-1 d-sm-none">
                Receptor:
                <span :class="{'text-muted font-italic': !paquete.receptor}">
                  {{ paquete.receptor ? paquete.receptor : 'Sin especificar' }}
                </span>
              </p>
              <p class="mb-0 d-sm-none">
                Instrucciones de envío:
                <!-- Dirección de envío: -->
                <span v-if="paquete.direccionDestino
                  && paquete.direccionDestino.ciudad
                  && paquete.direccionDestino.direccion"
                >
                  {{ paquete.direccionDestino.ciudad.estado.nombre }},
                  {{ paquete.direccionDestino.ciudad.nombre }},
                  {{ paquete.direccionDestino.direccion }}
                </span>
                <span v-else class="text-muted font-italic">Sin especificar</span>
              </p>
              <span v-if="paquete.observacion">
                Observación: {{ paquete.observacion }}
              </span>
            </div>
            <!-- Receptor -->
            <div class="col-4 col-md d-none d-sm-block">
              Receptor:
              <span :class="{'text-muted font-italic': !paquete.receptor}">
                {{ paquete.receptor ? paquete.receptor : 'Sin especificar' }}
              </span>
              <p class="mb-0">
                Instrucciones de envío:
                <!-- Dirección de envío: -->
                <span v-if="paquete.direccionDestino
                  && paquete.direccionDestino.ciudad
                  && paquete.direccionDestino.direccion"
                >
                  {{ paquete.direccionDestino.ciudad.estado.nombre }},
                  {{ paquete.direccionDestino.ciudad.nombre }},
                  {{ paquete.direccionDestino.direccion }}
                </span>
                <span v-else class="text-muted font-italic">Sin especificar</span>
              </p>
            </div>
          </li>
        </ul>
        <header class="font-weight-bold mt-2">Asignar el tipo de envío deseado</header>
        <div class="md-form md-outline outline-select my-2">
          <select
            id="tipo-select"
            v-model="paquete.envio"
            class="custom-select"
          >
            <option class="d-none" disabled value="">
              Seleccione
            </option>
            <option
              v-for="(key, value) in ConsolidadoTipoEnvio"
              :key="key"
              :value="value"
            >
              {{ key }}
            </option>
          </select>
        </div>
        <div class="row justify-content-center mt-3">
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            class="px-3"
            :disabled="botonDeshabilitado || !paquete.envio"
            @click="validarForm"
          >
            Agregar
          </mdb-btn>
        </div>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import paqueteModificarEnvioGql from '@/graphql/paqueteModificarEnvio.gql'
import { formatearFecha, mostrarSoloDosDecimales } from '@/funciones/funciones.js'
import { PaqueteEstatus, PaqueteTipos } from '@/constantes/paquetes.js'
import { ConsolidadoTipoEnvio, ConsolidadoTipoEnvioIconos } from '@/constantes/consolidados.js'
import clientePaquetesGql from '@/graphql/clientePaquetes.gql'
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle
} from 'mdbvue'
export default {
  name: 'FormularioInstruccionesEnvio',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true
    },
    paquetes: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    clienteId: {
      type: String,
      required: true
    },
    // Archivos y variables para updateQuery
    variablesUpdateQuery: {
      type: Object,
      required: false,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      direccionesDelCliente: [],
      instrucciones: [],
      paquete: {
        envio: ''
      },
      botonDeshabilitado: false,
      validarValores: {},
      PaqueteEstatus,
      ConsolidadoTipoEnvio,
      ConsolidadoTipoEnvioIconos,
      mostrarSoloDosDecimales,
      formatearFecha,
      PaqueteTipos
    }
  },
  watch: {
    mostrarModal: function () {
      if (!this.mostrarModal) {
        this.limpiarCampos()
      }
    }
  },
  methods: {
    validarForm () {
      this.botonDeshabilitado = true
      this.validarValores = {
        envio: this.paquete.envio.trim && this.paquete.envio.length ? 'valido' : 'invalido'
      }
      // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes('invalido')) {
        this.botonDeshabilitado = false
        return (this.alertaMensaje('El tipo de envío es requerido', 'advertencia'))
      }
      this.instruccionEnvioCrear()
    },
    instruccionEnvioCrear () {
      this.botonDeshabilitado = true
      const envio = this.paquete.envio
      const paquetesId = this.paquetes.map((p) => (p.id))
      const variablesMutate = {
        envio,
        paquetesId
      }
      this.$apollo
        .mutate({
          mutation: paqueteModificarEnvioGql,
          variables: variablesMutate,
          update: (store, { data: { paqueteModificarEnvio } }) => {
            const msg = paqueteModificarEnvio.codigo
            switch (msg) {
              case 'Correcto':
                this.alertaMensaje('Intrucción de envío creada correctamente.', 'correcto')
                break
              case 'Fallido':
                this.botonDeshabilitado = false
                return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
                  'error')
              default:
                this.botonDeshabilitado = false
                return this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                  'error')
            }
            const data = store.readQuery({
              query: clientePaquetesGql,
              variables: this.variablesUpdateQuery
            })

            paquetesId.forEach(id => {
              data.Cliente[0].paquetes = data.Cliente[0].paquetes.map((p) => {
                const paquete = p
                if (p.id === id) {
                  paquete.envio = envio
                }
                return paquete
              })
            })

            store.writeQuery({
              query: clientePaquetesGql,
              variables: this.variablesUpdateQuery,
              data
            })
            this.alertaMensaje('Intrucción de envío creada correctamente',
              'correcto')
            this.$emit('limpiarSelectPaquetes', true)
            this.cerrarModal()
            this.botonDeshabilitado = false
          }
        })
        .catch(() => {
          this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
            'error')
          this.botonDeshabilitado = false
        })
    },
    cerrarModal () {
      this.limpiarCampos()
      this.$emit('close', false)
    },
    alertaMensaje (contenido, tipo) {
      this.$emit('alertaMensaje', { contenido, tipo })
    },
    limpiarCampos () {
      this.botonDeshabilitado = false
      this.validarValores = {}
      this.paquete = {
        envio: ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
