<template>
  <mdb-modal
    centered
    elegant
    size="lg"
    scrollable
    :show="mostrarModal"
    @close="cerrarModal()"
  >
    <mdb-modal-header>
      <mdb-modal-title>{{ titulo }}</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body class="pt-0">
      <!-- Caso sin órdenes -->
      <div
        v-if="!ordenes.length && listarOrdenes"
        class="text-center text-muted user-select-none mb-4"
      >
        <font-awesome-icon
          icon="box-open"
          class="fa-5x mb-4"
        />
        <p class="h5-responsive text-center mb-0 mx-lg-5 px-lg-5">
          No tienes órdenes de reempaque registradas.
          Por favor agrega una orden de reempaque para continuar.
        </p>
      </div>
      <!-- Caso listar órdenes -->
      <div v-else-if="ordenes.length && listarOrdenes">
        <ul class="table" data-table="sticky">
          <li class="encabezado row">
            <div class="col-1" title="Número de orden">#</div>
            <div class="col-2 d-none d-lg-block">Tipo de envío</div>
            <div class="col d-none d-lg-block">Cantidad de paquetes</div>
            <div class="col d-lg-none">Detalles</div>
            <div class="col d-none d-sm-block">Dirección asignada</div>
            <div class="col-3 d-none d-lg-block">Receptor</div>
          </li>
          <li
            v-for="(orden, iO) in ordenes"
            :key="`orden${iO}`"
            class="contenido normal row"
          >
            <!-- Nro de orden -->
            <div class="col-1">{{iO+1}}</div>
            <!-- Tipo de envío -->
            <div class="col-2 d-none d-lg-block">
              <span
                :class="['d-block', {'text-muted user-select-none': !orden.envio}]"
              >
                {{ orden.envio ? ConsolidadoTipoEnvio[orden.envio] : 'Sin especificar' }}
              </span>
            </div>
            <div class="col d-none d-lg-block">
              {{ orden.paquetes.length }} paquetes
              <p v-if="orden.observacion" class="mb-0 mt-1">
                Observación:
                <span
                  :class="{'text-muted font-italic user-select-none': !orden.observacion}"
                >
                  {{ orden.observacion ? orden.observacion : 'Sin especificar' }}
                </span>
              </p>
            </div>
            <!-- Detalles -->
            <div class="col d-lg-none">
              <p class="mb-0">
                Envío:
                <span
                  :class="{'text-muted user-select-none': !orden.envio}"
                >
                  {{ orden.envio ? ConsolidadoTipoEnvio[orden.envio] : 'Sin especificar' }}
                </span>
              </p>
              <p class="mb-0">
                Paquetes: {{ orden.paquetes.length }}
              </p>
              <p v-if="orden.observacion" class="mb-0">
                Observación:
                <span
                  :class="{'text-muted font-italic user-select-none': !orden.observacion}"
                >
                  {{ orden.observacion ? orden.observacion : 'Sin especificar' }}
                </span>
              </p>
              <p class="mb-0 mt-1 d-sm-none">
                Dirección asignada:
                <span v-if="orden.direccion && orden.direccion.id" class="d-block">
                  {{ orden.direccion.ciudad.nombre }},
                  {{ orden.direccion.ciudad.estado.nombre }},
                  {{ orden.direccion.ciudad.estado.pais.nombre }}.
                </span>
                <span v-if="orden.direccion && orden.direccion.id">
                  {{orden.direccion.direccion}}
                </span>
                <span v-else class="text-muted font-italic user-select-none">Sin especificar</span>
              </p>
              <div class="mb-0 mt-1">
                Receptor:
                <div v-if="orden.direccion && orden.direccion.id">
                  <span
                    :class="{'text-muted font-italic user-select-none': !(orden.direccion.recibe && orden.direccion.recibe.nombre)}"
                  >
                    {{ orden.direccion.recibe && orden.direccion.recibe.nombre ?  orden.direccion.recibe.nombre : 'Sin especificar'}}
                  </span>
                  <ul
                    v-if="orden.direccion.recibe && orden.direccion.recibe.telefonos && orden.direccion.recibe.telefonos.length"
                    class="list-unstyled mb-0"
                  >
                    <li
                      v-for="(tel, i) in orden.direccion.recibe.telefonos"
                      :key="`tel${i}`"
                      class="d-inline"
                    >
                      {{i > 0 ? ', ' : ''}}
                      <a
                        :href="`tel:+${tel.numero}`"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        +{{tel.numero}}
                      </a>
                    </li>
                  </ul>
                  <span v-else class="text-muted font-italic user-select-none d-block">
                    Sin teléfono de contacto
                  </span>
                </div>
                <span v-else class="text-muted font-italic user-select-none">Sin especificar</span>
              </div>
            </div>
            <div class="col d-none d-sm-block">
              <span v-if="orden.direccion && orden.direccion.id" class="d-block">
                {{ orden.direccion.ciudad.nombre }},
                {{ orden.direccion.ciudad.estado.nombre }},
                {{ orden.direccion.ciudad.estado.pais.nombre }}.
              </span>
              <span v-if="orden.direccion && orden.direccion.id">
                {{orden.direccion.direccion}}
              </span>
              <span v-else class="text-muted font-italic user-select-none">Sin especificar</span>
            </div>
            <div class="col-3 d-none d-lg-block">
              <span
                v-if="orden.direccion && orden.direccion.id"
                :class="{'text-muted font-italic user-select-none': !(orden.direccion.recibe && orden.direccion.recibe.nombre)}"
              >
                {{ orden.direccion.recibe && orden.direccion.recibe.nombre ?  orden.direccion.recibe.nombre : 'Sin receptor'}}
              </span>
              <span v-else class="text-muted font-italic user-select-none">Sin receptor</span>
              <ul
                v-if=" orden.direccion && orden.direccion.recibe &&  orden.direccion.recibe.telefonos && orden.direccion.recibe.telefonos.length"
                class="list-unstyled mb-0"
              >
                <li
                  v-for="(tel, i) in orden.direccion.recibe.telefonos"
                  :key="`tel${i}`"
                  class="d-inline"
                >
                  {{i > 0 ? ', ' : ''}}
                  <a
                    :href="`tel:+${tel.numero}`"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    +{{tel.numero}}
                  </a>
                </li>
              </ul>
              <span v-else class="text-muted font-italic user-select-none d-block">
                Sin teléfono de contacto
              </span>
            </div>
          </li>
        </ul>
      </div>
      <!-- Caso crear órden de reempaque -->
      <div v-else-if="!listarOrdenes">
        <header class="font-weight-bold">Paquetes seleccionados</header>
        <ul class="table" data-table="sticky">
          <li class="encabezado row">
            <div class="col-3 col-sm-2 col-lg-1">Fecha</div>
            <div class="col-4 col-md-3 d-none d-lg-block">Estatus</div>
            <div class="col">Detalles</div>
            <div class="col-4 col-md d-none d-sm-block">Receptor</div>
          </li>
          <li
            v-for="paquete in paquetes"
            :key="`paquete-${paquete.id}`"
            class="contenido normal row align-items-center"
          >
          <!-- Fecha recibido -->
            <div class="col-3 col-sm-2 col-lg-1">
              <span class="d-block">
                {{ formatearFecha(paquete.fecha.formatted).ddmm }}
              </span>
              {{ formatearFecha(paquete.fecha.formatted).yyyy }}
            </div>
            <!-- Estatus -->
            <div class="col-4 col-md-3 d-none d-lg-block">
              <img
                v-if="paquete.estatus && paquete.estatus.length"
                :src="`/img/iconos-vistas/estatus-paquete/${paquete.estatus}.svg`"
                alt="Icono"
                height="18"
                width="18"
              >
              {{PaqueteEstatus[paquete.estatus]}}
              <p class="mb-0">
                Envío:
                <font-awesome-icon
                  v-if="paquete.envio"
                  :icon="ConsolidadoTipoEnvioIconos[paquete.envio]"
                  class="texto-terciario ml-1"
                />
                <span :class="{'text-muted font-italic': !paquete.envio}">
                  {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : 'Sin especificar' }}
                </span>
              </p>
              <p class="mb-0">
                Tipo:
                <img
                  v-if="paquete.tipo && paquete.tipo.length"
                  :src="`/img/iconos-vistas/paquete-tipo/${paquete.tipo}.svg`"
                  alt="Icono"
                  height="18"
                  width="18"
                >
                <span :class="{'text-muted font-italic': !paquete.tipo}">
                  {{ paquete.tipo ? paquete.tipo : 'Sin especificar' }}
                </span>
              </p>
            </div>
            <!-- Detalles -->
            <div class="col">
              <!-- Columna estatus -->
              <div class="d-lg-none mb-1">
                <img
                  v-if="paquete.estatus && paquete.estatus.length"
                  :src="`/img/iconos-vistas/estatus-paquete/${paquete.estatus}.svg`"
                  alt="Icono"
                  height="18"
                  width="18"
                >
                {{PaqueteEstatus[paquete.estatus]}}
                <p class="mb-0">
                  Envío:
                  <font-awesome-icon
                    v-if="paquete.envio"
                    :icon="ConsolidadoTipoEnvioIconos[paquete.envio]"
                    class="texto-terciario ml-1"
                  />
                  <span :class="{'text-muted font-italic': !paquete.envio}">
                    {{ paquete.envio ? ConsolidadoTipoEnvio[paquete.envio] : 'Sin especificar' }}
                  </span>
                </p>
                <p class="mb-0">
                  Tipo:
                  <img
                    v-if="paquete.tipo && paquete.tipo.length"
                    :src="`/img/iconos-vistas/paquete-tipo/${paquete.tipo}.svg`"
                    alt="Icono"
                    height="18"
                    width="18"
                  >
                  <span :class="{'text-muted font-italic': !paquete.tipo}">
                    {{ paquete.tipo ? paquete.tipo : 'Sin especificar' }}
                  </span>
                </p>
              </div>
              Tracking:
              <span :class="{'text-muted font-italic': !paquete.tracking}">
                {{ paquete.tracking ? paquete.tracking : 'Sin especificar' }}
              </span>
              <p class="mb-0">
                Pies cúbicos:
                <span title="Pies cúbicos">
                  {{
                    mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 1728)
                  }} ft<sup>3</sup>
                </span>
              </p>
              Peso:
              <span title="Libras">
                {{ mostrarSoloDosDecimales(paquete.peso) }} lb
              </span>
              <p class="mb-0">
                Peso por volúmen:
                <span title="Libras">
                  {{
                    mostrarSoloDosDecimales((paquete.ancho * paquete.alto * paquete.largo) / 166)
                  }} lb
                </span>
              </p>
              <!-- Columna receptor -->
              <p class="mb-0 mt-1 d-sm-none">
                Receptor:
                <span :class="{'text-muted font-italic': !paquete.receptor}">
                  {{ paquete.receptor ? paquete.receptor : 'Sin especificar' }}
                </span>
              </p>
              <p class="mb-0 d-sm-none">
                Instrucciones de envío:
                <!-- Dirección de envío: -->
                <span v-if="paquete.direccionDestino
                  && paquete.direccionDestino.ciudad
                  && paquete.direccionDestino.direccion"
                >
                  {{ paquete.direccionDestino.ciudad.estado.nombre }},
                  {{ paquete.direccionDestino.ciudad.nombre }},
                  {{ paquete.direccionDestino.direccion }}
                </span>
                <span v-else class="text-muted font-italic">Sin especificar</span>
              </p>
              <span v-if="paquete.observacion">
                Observación: {{ paquete.observacion }}
              </span>
            </div>
            <!-- Receptor -->
            <div class="col-4 col-md d-none d-sm-block">
              Receptor:
              <span :class="{'text-muted font-italic': !paquete.receptor}">
                {{ paquete.receptor ? paquete.receptor : 'Sin especificar' }}
              </span>
              <p class="mb-0">
                Instrucciones de envío:
                <!-- Dirección de envío: -->
                <span v-if="paquete.direccionDestino
                  && paquete.direccionDestino.ciudad
                  && paquete.direccionDestino.direccion"
                >
                  {{ paquete.direccionDestino.ciudad.estado.nombre }},
                  {{ paquete.direccionDestino.ciudad.nombre }},
                  {{ paquete.direccionDestino.direccion }}
                </span>
                <span v-else class="text-muted font-italic">Sin especificar</span>
              </p>
            </div>
          </li>
        </ul>
        <header class="font-weight-bold mt-2">Asignar el tipo de envío deseado</header>
        <div class="md-form md-outline outline-select my-2">
          <select
            id="tipo-select"
            v-model="paquete.envio"
            class="custom-select"
          >
            <option class="d-none" disabled value="">
              Seleccione
            </option>
            <option
              v-for="(key, value) in ConsolidadoTipoEnvio"
              :key="key"
              :value="value"
            >
              {{ key }}
            </option>
          </select>
        </div>
        <header class="font-weight-bold mt-2">
          Asignar dirección al reempaque <small class="text-muted">(opcional)</small>
        </header>
        <div
          :class="['md-form md-outline outline-select my-2',
            {'cursor-not-allowed': !direccionesDelCliente.length}]"
        >
          <select
            id="direccion-select"
            v-model="paquete.direccion"
            class="custom-select"
            :disabled="!direccionesDelCliente.length"
          >
            <option class="d-none" disabled :value="{}">{{
              direccionesDelCliente.length
                ? 'Selecciona una dirección'
                : 'No cuentas con direcciones'
              }}
            </option>
            <option
              v-for="(direccion, i) in direccionesDelCliente"
              :key="i+direccion.id"
              :value="direccion"
            >
              <!--  -->
              {{ direccion.ciudad.estado.nombre }},
              {{ direccion.ciudad.nombre }}
              <!-- Dirección-->
              - {{ direccion.direccion }}
              <!-- Persona que recibe-->
              - Receptor:
                {{ direccion.recibe && direccion.recibe.nombre
                  ?  direccion.recibe.nombre : 'Sin especificar'
                }}
            </option>
          </select>
        </div>
        <header class="font-weight-bold mt-2">Observación <small class="text-muted">(opcional)</small></header>
        <mdb-input
          v-model.trim="paquete.observacion"
          type="textarea"
          placeholder="Ej: Reempacar en sobre"
          class="my-2"
          outline
        />
        <div class="row justify-content-center mt-3">
          <mdb-btn
            color="primario"
            :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
            :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
            class="px-3"
            :disabled="botonDeshabilitado"
            @click="validarForm"
          >
            Agregar
          </mdb-btn>
        </div>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>

<script>
import gql from 'graphql-tag'
import ordenReempaqueCrearGql from '@/graphql/ordenReempaqueCrear.gql'
import ordenesReempaqueGql from '@/graphql/ordenesReempaque.gql'
import clienteBuscarDireccionesGql from '@/graphql/clienteBuscarDirecciones.gql'
import { formatearFecha, mostrarSoloDosDecimales } from '@/funciones/funciones.js'
import { PaqueteEstatus, PaqueteTipos } from '@/constantes/paquetes.js'
import { ConsolidadoTipoEnvio, ConsolidadoTipoEnvioIconos } from '@/constantes/consolidados.js'
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput
} from 'mdbvue'
export default {
  name: 'FormularioOrdenReempaque',
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput
  },
  props: {
    mostrarModal: {
      type: Boolean,
      required: true
    },
    esEditar: {
      type: Boolean,
      required: false
    },
    listarOrdenes: {
      type: Boolean,
      required: false
    },
    paquetes: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    clienteId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      direccionesDelCliente: [],
      ordenes: [],
      paquete: {
        direccion: {},
        envio: ''
      },
      botonDeshabilitado: false,
      validarValores: {},
      PaqueteEstatus,
      ConsolidadoTipoEnvio,
      ConsolidadoTipoEnvioIconos,
      mostrarSoloDosDecimales,
      formatearFecha,
      PaqueteTipos
    }
  },
  watch: {
    mostrarModal: function () {
      if (!this.mostrarModal) {
        this.limpiarCampos()
      } else {
        this.clienteBuscarOrdenes()
        if (!this.listarOrdenes) {
          this.clienteBuscarDirecciones()
        }
      }
    }
  },
  methods: {
    validarForm () {
      this.botonDeshabilitado = true
      this.validarValores = {
        envio: this.paquete.envio.trim && this.paquete.envio.length ? 'valido' : 'invalido'
      }
      // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes('invalido')) {
        this.botonDeshabilitado = false
        return (this.alertaMensaje('El tipo de envío es requerido', 'advertencia'))
      }
      // Verificar con Includes si ya existen paquetes en alguna orden activa
      let paqueteContenido = false
      this.paquetes.map((p) => {
        if (!paqueteContenido) {
          this.ordenes.map(orden => {
            const encontrado = orden.paquetes.map(paquete => paquete.id).includes(p.id)
            if (encontrado) {
              paqueteContenido = true
            }
          })
        }
      })
      paqueteContenido
        ? this.alertaMensaje('Algún paquete seleccionado se encuentra dentro de una orden ' +
            'de reempaque activa. Por favor verifica tus datos', 'advertencia')
        : this.ordenReempaqueCrear()
      this.botonDeshabilitado = false
    },
    ordenReempaqueCrear () {
      this.botonDeshabilitado = true
      const envio = this.paquete.envio
      const observacion = this.paquete.observacion
      const direccionId = this.paquete.direccion ? this.paquete.direccion.id : ''
      const paquetesId = this.paquetes.map((p) => (p.id))
      const personaId = this.clienteId
      // Datos para asignar a updateQuery
      const orden = {
        envio,
        paquetes: this.paquetes,
        __typename: 'OrdenReempaque'
      }
      const variablesMutate = {
        personaId,
        ordenReempaque: {
          envio
        },
        paquetesId
      }
      // Observación es opcional
      if (observacion && observacion.length) {
        Object.assign(variablesMutate, {
          ordenReempaque: {
            envio,
            observacion
          }
        })
        // Asignar a updateQuery
        Object.assign(orden, {
          observacion
        })
      }
      // dirección es opcional
      if (direccionId && direccionId.length) {
        Object.assign(variablesMutate, {
          direccionId
        })
        // Asignar a updateQuery
        Object.assign(orden, {
          direccion: this.paquete.direccion
        })
      }
      this.$apollo
        .mutate({
          mutation: ordenReempaqueCrearGql,
          variables: variablesMutate
        })
        .then(({ data: { crearOrdenReempaque } }) => {
          if (crearOrdenReempaque.id) {
            this.alertaMensaje('Orden creada correctamente',
              'correcto')
            this.$emit('limpiarSelectPaquetes', true)
            this.cerrarModal()
          } else {
            this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
              'error')
          }
          this.botonDeshabilitado = false
        })
        .catch(() => {
          this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos',
            'error')
          this.botonDeshabilitado = false
        })
    },
    cerrarModal () {
      this.limpiarCampos()
      this.$emit('close', false)
    },
    alertaMensaje (contenido, tipo) {
      this.$emit('alertaMensaje', { contenido, tipo })
    },
    limpiarCampos () {
      this.botonDeshabilitado = false
      this.validarValores = {}
      this.paquete = {
        direccion: {},
        envio: ''
      }
    },
    clienteBuscarDirecciones () {
      const id = this.clienteId
      this.botonDeshabilitado = true
      this.$apollo
        .watchQuery({
          query: gql`
            ${clienteBuscarDireccionesGql}
          `,
          variables: {
            id
          },
          update: (data) => data.Cliente[0],
          fetchPolicy: 'cache-and-network'
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.Cliente && data.Cliente[0].direcciones) {
              this.direccionesDelCliente = data.Cliente[0].direcciones
            }
            this.botonDeshabilitado = false
          },
          error: () => {
            this.alertaMensaje('Ha ocurrido un error obteniendo las direcciones.' +
                  ' Por favor intenta de nuevo', 'error')
            this.botonDeshabilitado = false
          }
        })
    },
    clienteBuscarOrdenes () {
      const id = this.clienteId
      this.botonDeshabilitado = true
      this.$apollo
        .watchQuery({
          query: gql`
            ${ordenesReempaqueGql}
          `,
          variables: {
            filter: {
              cliente: {
                id
              }
            }
          },
          update: (data) => data.OrdenReempaque,
          fetchPolicy: 'cache-and-network'
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.OrdenReempaque) {
              this.ordenes = data.OrdenReempaque
            }
            this.botonDeshabilitado = false
          },
          error: () => {
            this.alertaMensaje('Ha ocurrido un error obteniendo las ordenes de reempaque.' +
                  ' Por favor intenta de nuevo', 'error')
            this.botonDeshabilitado = false
          }
        })
    }
  },
  computed: {
    titulo () {
      return this.listarOrdenes
        ? 'Órdenes de reempaque'
        : 'Orden de reempaque'
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
